import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'substring'
})
export class SubstringPipe implements PipeTransform {

  transform(input: string = '', subAt = 5): string {
    return input.substring(input.length - subAt);
  }

}
