import {ReservationItem} from '@app/core';

export class WorkOrder {
  id?: string;
  isArchived: boolean;
  outsmartWorkOrderId?: string;
  referenceId?: string;
  isCreatedFromTicket?: boolean;
  isPlannedWork?: boolean;
  referenceNumber?: string;
  ticketId?: string;
  ticketNumber?: string;
  ticketTotalWorkOrdersCount?: number;
  workTypeId?: string;
  companyTypeId?: string;
  workSheetCode?: string;
  errorNotAllPeriodsInExact?: boolean;
  workOrderPeriodsWithWorkTypeAndZeroCostsCount?: number;

  prio?: number;
  comment?: string;
  notificationDate?: Date;
  startWorkDate?: Date;
  estimatedEndWorkDate?: Date;
  notifier?: string;

  locationId?: string;
  locationReferenceId?: string;
  locationName?: string;
  locationStreet?: string;
  locationCity?: string;
  locationLatitude?: number;
  locationLongitude?: number;

  createdByUserName?: string;
  createdDate?: Date;
  createdByUserPhoneNumber: string;
  createdByUserEmail: string;
  updatedByUserName?: string;
  updatedDate?: Date;

  isCompleted?: boolean;

  workOrderStatus?: string;
  workOrderStatusId?: string;
  commentMechanic?: string;
  allCommentsCombined?: string;
  pdfUrl?: string;
  worksheetCode?: string;

  photosCount?: number;
  workPeriodsCount?: number;
  materialsCount?: number;
  materialsWithoutPriceCount?: number;
  materialsWithPriceCount?: number;

  totalKilometers?: number;
  totalAllHours?: number;
  totalWorkHours?: number;
  totalTravelHours?: number;

  functionalRepairDate?: Date;
  permanentRepairDate?: Date;
  hasBlockageRoad?: boolean;
  blockageRoadEndDate?: Date;
  hasBlockageWaterway?: boolean;
  blockageWaterWayEndDate?: Date;
  installationPartId?: string;
  installationPartName?: string;
  cause?: string;
  consequence?: string;
  advice?: string;

  // Mechanics
  mechanicId?: string;
  mechanicName?: string;
  mechanicFunction?: number;
  mechanics?: {id: string, function: number}[];

  // Project
  projectId?: string;
  projectName?: string;
  projectItemId?: string;
  projectItemName?: string;
  projectNumber?: string;
  projectItemExactId?: string;
  projectIsActive?: boolean;
  projectEstimatedStartDate?: Date;
  projectEstimatedEndDate?: Date;

  // Reservations
  reservationsCount?: number;
  reservationItemIds?: string[];
  reservationItems?: ReservationItem[];
}
