// noinspection TypeScriptPreferShortImport

import {CompanyTypeByIdPipe} from './company-types/company-type-by-id.pipe';
import {FindInMechanicsPipe} from './mechanics/find-in-mechanics.pipe';
import {MechanicByIdPipe} from './mechanics/mechanic-by-id.pipe';
import {FindInNotifiersPipe} from './notifiers/find-in-notifiers.pipe';
import {TicketStripTicketNumber} from './tickets/ticket-strip-ticket-number';
import {WorkTypeByIdPipe} from './work-types/work-type-by-id.pipe';
import {CalendarNoteTypeByIdPipe} from '@app/shared/pipes/calendar-note-types/calendar-note-type-by-id.pipe';
import {FromNowPipe} from '@app/shared/pipes/from-now.pipe';
import {SubstringPipe} from '@app/shared/pipes/substring.pipe';

export const pipes = [
  CompanyTypeByIdPipe,
  FindInMechanicsPipe,
  MechanicByIdPipe,
  FindInNotifiersPipe,
  TicketStripTicketNumber,
  WorkTypeByIdPipe,
  CalendarNoteTypeByIdPipe,
  FromNowPipe,
  SubstringPipe
];

// noinspection TypeScriptPreferShortImport
export {CompanyTypeByIdPipe} from './company-types/company-type-by-id.pipe';
export {FindInMechanicsPipe} from './mechanics/find-in-mechanics.pipe';
export {MechanicByIdPipe} from './mechanics/mechanic-by-id.pipe';
export {FindInNotifiersPipe} from './notifiers/find-in-notifiers.pipe';
export {TicketStripTicketNumber} from './tickets/ticket-strip-ticket-number';
export {WorkTypeByIdPipe} from './work-types/work-type-by-id.pipe';
export {CalendarNoteTypeByIdPipe} from '@app/shared/pipes/calendar-note-types/calendar-note-type-by-id.pipe';
export {SubstringPipe} from '@app/shared/pipes/substring.pipe';
